import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import NetChange from '../components/ui/net-change';
import BookAd from '../components/ui/book-ad';
import ItemTitle from '../components/ui/item-title';
import ItemPrice from '../components/ui/item-price';

import RateChart from '../components/ui/rate-chart-preview';

import { sortByName, getIndexer } from '../util';

const IndexPage = ({ data }) => {

  // console.log({ data: data });

  const publi = data.allPubliJson.edges.map(item => item.node)

  publi.sort(() => Math.random() - 0.5)

  const ads = publi.slice(0, 6)

  data.dataJson.compra.sort(sortByName);
  data.dataJson.venda.sort(sortByName);

  return (

    <Layout>
      <SEO title="Home" description="Acompanhe a variação diária e o gráfico da taxa de rentabilidade dos títulos do Tesouro Direto" lang='pt-br' />

      <div className="sm:flex mb-4">

        <div className="flex-1">
          <h2 className="mb-4 bold text-center sm:text-left sm:text-xl">Títulos disponíveis para investimento</h2>
        </div>

        <div className="flex-1 text-center sm:text-right">
          <span className="text-xs text-gray-600">Atualizado em {data.dataJson.mercado.str_ts}</span> &middot; <a className="text-xs underline" href="https://www.tesourodireto.com.br/titulos/precos-e-taxas.htm" target="_blank" rel="noopener">Fonte</a>
        </div>

      </div>
      <div className="hidden sm:flex m-2 text-gray-500 text-sm">
        <div style={{ flex: 2 }}>Título</div>
        <div className="flex-1 text-right">Taxa de Rendimento (% a.a.)</div>
      </div>
      <div>
        {data.dataJson.compra.map((item, index) => {

          const hist = item.hist.map((p) => {
            return { x: new Date(p.ts), y: parseFloat(p.rate) };
          });

          return (<div key={item.id} className="mt-2 p-2" >
          <a href={`investir/${item.link}/`} >
            <div key={index} property="offers" typeof="Offer" className="flex p-4 rounded" style={{ backgroundColor: '#f8fafc' }}>
              <meta property="offeredBy" content="http://www.tesouro.fazenda.gov.br/tesouro-direto" />
              <div className="flex-1 grow md:flex justify-items-center">
                <div className="flex-1 flex">
                  <div className="flex-1">
                    <ItemTitle item={item} />
                  </div>
                  <div className="flex-initial pr-8 hidden md:block">
                    <ItemPrice item={item} />
                  </div>                  
                  <div className="flex-initial w-44">
                    <NetChange item={item} />
                  </div>
                </div>
                <div className="flex-initial md:w-56">
                  <RateChart data={hist} height={180} />
                </div>
              </div>        
              </div>
          </a>

          {index == 2 && ads && <div className="bg-white">
            <div className="text-sm text-gray-500 mt-2 text-center">
              Publicidade
            </div>          
          <div className="flex">
            {ads.map((ad, adCount) => {
              
              const className = adCount > 2 ? 'flex-1 hidden md:block' : 'flex-1';
              
              return (<div className={className}>
                <BookAd item={ad} />
              </div>
              )}
            )}
          </div>
          <div className="mt-2 text-center p-2">
              <a
                className="text-sm text-gray-700 text-center"
                target="_blank"
                href="https://www.amazon.com.br/b?_encoding=UTF8&tag=tt0551-20&linkCode=ur2&linkId=a727a069d6bbfcd91e2c02b243793ac3&camp=1789&creative=9325&node=7872854011"
              >
                Mais sugestões de livros
              </a>
            </div>
              </div>}
          </div>);
        })}
      </div>

      <br />

      <h2 className="mb-4 bold sm:text-xl">Resgate</h2>

      <div className="hidden sm:flex m-2 text-gray-500 text-sm">
        <div style={{ flex: 2 }}>Título</div>
        <div className="hidden sm:block flex-1 text-right">Preço Unitário</div>
        <div className="flex-1 text-right">Taxa de Rendimento (% a.a.)</div>
      </div>
      {data.dataJson.venda.map((item, index) => (
        <a key={item.id} href={`resgatar/${item.link}/`}>
          <div className="flex p-1 border-b hover:bg-green-100 hover:shadow">

            <div className="text-sm sm:text-lg" style={{ flex: 2 }}>
              <span property="name">{item.name}</span>
            </div>
            <div className="hidden sm:block flex-1 ml-2 text-right text-gray-700">
              {item.str_price}
            </div>
            <div className="flex-1 ml-2 text-right">
              {getIndexer(item.name)}{item.rate}%
          </div>

          </div>
        </a>))}
    </Layout>
  )
};

export default IndexPage

export const query = graphql`
  query {
    dataJson {
      compra {
        name
        id
        link
        rate
        maturity_at
        str_price
        str_min_amount
        net_change
        hist {
          ts
          rate
        }
      }
      venda {
        id
        link
        name
        rate
        maturity_at
        str_price
      }
      mercado {
        ts
        str_ts
      }
    }
    allPubliJson {
      edges {
        node {
          title
          img
          url
        }
      }
    }    
  }
`
