import React from "react"

const ItemTitle = ({ item }) => {
  return (
    <div>
      <div className="text-lg md:text-2xl" property="name">
        {item.name}
      </div>
      {/* <div className="hidden sm:block text-sm text-gray-600">
        Vencimento em {item.maturity_at}
      </div> */}
      <div className="sm:hidden text-sm text-gray-500">
        Investimento mínimo {item.str_min_amount}
      </div>      
    </div>
  )
}

export default ItemTitle
