import React from "react"

import {
  VictoryChart,
  VictoryLine,
  VictoryContainer,
  VictoryAxis,
} from "victory"

const RateChart = ({ data, className, height = 180 }) => {
  return (
    <div className={className}>
      <VictoryChart
        height={height}
        scale={{ x: "time" }}
        containerComponent={
          <VictoryContainer
            style={{
              pointerEvents: "auto",
              userSelect: "auto",
              touchAction: "auto",
            }}
          />
        }
      >
        <VictoryLine
          data={data}
          style={{
            data: { stroke: "#2f855a" },
          }}
        ></VictoryLine>
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "transparent" },
          }}
        />
      </VictoryChart>
    </div>
  )
}

export default RateChart
