import React from "react"

import { getIndexer } from "../../util"

const NetChange = ({ item }) => {
  return (
    <div className="text-right text-lg md:text-2xl">
      <div className="font-semibold">
        <span
          property="interestRate"
          typeof="QuantitativeValue"
          content={item.rate}
        >
          <span className="text-gray-600">{getIndexer(item.name)}</span>
          <span className="text-green-700">{item.rate}%</span>
        </span>
      </div>
      <div className="text-sm text-gray-500">Variação {item.net_change}</div>
    </div>
  )
}

export default NetChange
