import React from "react"

const BookAd = ({ item }) => {
  return (
    <div className="flex-1 flex justify-center p-2 mt-6">
      <div style={{ width: "100px" }}>
        <a href={item.url} target="_blank" title={item.title}>
          <img src={item.img} alt={item.title} />
        </a>
      </div>
    </div>
  )
}

export default BookAd
