import React from "react"

const ItemPrice = ({ item }) => {
  return (
    <div className="text-lg md:text-2xl text-right">
      <div property="priceSpecification" typeof="UnitPriceSpecification">
        <meta property="priceCurrency" content="BRL" />
        <span className="text-gray-600" property="price" content={item.price}>
          {item.str_price}
        </span>
      </div>
      <div className="text-sm text-gray-500">
        Investimento mínimo {item.str_min_amount}
      </div>
    </div>
  )
}

export default ItemPrice
